<template>
  <div class="header clearfix">
    <el-dropdown class="right">
      <div class="head-img el-dropdown-link">
        <el-avatar style="margin-top: 0px; float: left; margin-right: 10px" :size="50">
          <img src="../../assets/img/head-img.png" />
        </el-avatar>
        <span style="padding-top: 15px; float: right">{{ userInfo }}</span>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <!-- <el-dropdown-item
            ><router-link to="/user/profile"
              >个人中心</router-link
            ></el-dropdown-item
          > -->
          <el-dropdown-item><router-link to="/user/resetPwd">修改密码</router-link></el-dropdown-item>
          <el-dropdown-item>
            <p @click="loginout">退出登录</p>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-badge :is-dot="true" class="messages right" icon="el-icon-edit" v-if="showMsgDot">
      <el-icon style="font-size: 24px" @click="showMsg"><Bell /></el-icon>
    </el-badge>
    <el-badge :is-dot="false" class="messages right" v-else>
      <el-icon style="font-size: 24px" @click="showMsg"><Bell /></el-icon>
    </el-badge>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, onUnmounted } from "vue";
import { User } from "@/components/AuthorizationDomain/User";
const { proxy } = getCurrentInstance();
const instance = getCurrentInstance();
import { h } from "vue";
import { ElLink } from "element-plus";
const UserDomain = new User(instance?.appContext.config.globalProperties.TokenClient, instance?.appContext.config.globalProperties.Services.Authorization);
const userInfo = ref();
let showMsgDot = ref(false);
// 消息通知
initMsg();
onMounted(() => {
  userInfo.value = UserDomain.GetSessionUserInfo().name;
});
onUnmounted(() => {
  window.removeEventListener("onmessageWS", getSocketData);
});
function initMsg() {
  window.addEventListener("onmessageWS", getSocketData);
  // 检测浏览器是否支持Websocket
  if (typeof WebSocket == "undefined") {
    proxy.$alert("系统检测到您的浏览器不支持Websocket通信协议，这将会导致您无法正常进行课堂互动！建议您更换浏览器进行观看！", "警告", {
      confirmButtonText: "知道了",
      callback: () => {},
    });
  }
}
function getSocketData(res) {
  let data = res.detail.data;
  if (data.Code == 200 && data.Data) {
    let msg = res.detail.data.Data;
    showMsgDot.value = true;
    openPage(data.Data);
  }
}
function openPage(res) {
  let msg = `${res.Content}`;
  let query = {};
  let path = "";
  let tip = "";
  //  消息类型 type ： 0 系统消息    2 快递消息  3 支付消息 （支出和入账）4 账号消息5 申请消息6 门诊消息 7 转诊消息  8 升级续费消息
  //  子类型 SubType：
  //  机构后台门诊消息  1付款成功开始备药  2退款申请通知      3库存预警消息     4效期预警消息
  //  总后台 5机构提现申请消息  6机构升级版本申请  7机构申请入驻   8店铺开通申请 9商品审核申请  10平台介入申请 11 议价申请消息
  //  医生端/app/小程序 门诊消息   12审方医生消息    13  审核成功通知  14 审核拒绝通知  15退款拒绝通知
  //  医生端/app/小程序 账号/会员消息  16会员即将到期  17会员已到期
  //  代理商后台升级续费消息  18续费升级申请  19对公审批完成  20 议价审批通知
  //  代理商后台账号消息  21机构审核申请 22店铺开通申请
  switch (res.SubType) {
    case 9:
      path = "/ShopMall/auditCommodity";
      tip = "去处理";
      break;
    case 18:
      path = "/Finance/orgOrderpay";
      // query = { orderNum: `${JSON.parse(res.JumpOp).OrderNum}` };
      tip = "去处理";
      break;
      // case 19:
      //   path = "/Finance/banKpayOrder";
      //   tip = "去处理";
      break;
    case 20:
      path = "/Finance/orgOrderpay";
      // query = { orderNum: `${JSON.parse(res.JumpOp).OrderNum}` };
      tip = "去处理";
      break;
    case 21:
      path = "/user/OrgAudit";
      tip = "去处理";
      break;
    case 22:
      path = "/user/MerchantAudit";
      tip = "去处理";
      break;
  }
  openMessage(res.Title, path, query, msg, tip);
}
function openMessage(title, path, query, msg, tip) {
  let notify = proxy.$notify({
    title: title,
    message: h(
      "span",
      {
        style: {
          "word-break": "break-all",
          "overflow-wrap": "break-word",
        },
      },
      [
        msg,
        h(
          ElLink,
          {
            type: "primary",
            onClick: (event) => {
              // 阻止默认行为
              event.preventDefault();
              if (proxy.$route.path !== path) {
                proxy.$router.push({
                  path: path,
                  query: query,
                });
              }
              notify.close();
              showMsgDot.value = false;
            },
          },
          tip
        ),
      ]
    ),
    position: "bottom-right",
    duration: 0,
    offset: 20,
    type: "warning",
    dangerouslyUseHTMLString: true,
  });
}
function loginout() {
  proxy
    .$confirm("此操作将注销登录, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      window.sessionStorage.clear();
      window.localStorage.clear();
      proxy.$socket.close();
      proxy.$message.success("已退出");
      proxy.$router.push("/login");
      //触发后禁止浏览器的后退键
      history.pushState(null, null, document.URL);
      window.addEventListener(
        "popstate",
        function (e) {
          history.pushState(null, null, document.URL);
        },
        false
      );
    })
    .catch(() => {
      proxy.$message({
        type: "info",
        message: "已取消操作",
      });
    });
}
function showMsg() {
  proxy.$router
    .push({
      path: "/System/MsgList",
      query: {},
    })
    .catch(() => {});
}
</script>

<style scoped>
.header {
  flex: 1;
  text-align: right;
  margin-right: 15px;
}

.messages {
  margin-top: 13px;
  margin-right: 20px;
}

.header :deep() .el-badge__content.is-dot {
  width: 12px;
  height: 12px;
}
</style>
