<template>
  <div class="baidu_map">
    <div id="map" :style="{ width, height }"></div>
    <div class="search-div">
      <el-autocomplete style="width:100%;" popper-class="autoAddressClass" v-model="mapData.address"
        :fetch-suggestions="querySearch" :trigger-on-focus="false" placeholder="请输入详细地址" @select="handleSelect" clearable>
        <template #default="{ item }">
          <div class="autoAddressClass_item">
            <ElIcon :size="20" color="balck">
              <Search />
            </ElIcon>
            <div>
              <div class="title">{{ item.title }}</div>
              <span class="address ellipsis">{{ item.address }}</span>
            </div>
          </div>
        </template>
      </el-autocomplete>
    </div>
  </div>
</template>
 
<script setup>
import { ref, onMounted, defineExpose, getCurrentInstance, reactive } from "vue";
import { Search } from '@element-plus/icons-vue'
// 初始化经纬度 宽高 层级
// lng: 116.403961, lat: 39.915112
let { latitude, longitude, width, height, zoom } = defineProps({
  latitude: {
    type: String,
    default: '34.723703941937025'
  },
  longitude: {
    type: String,
    default: '113.6627903713419',
  },
  width: {
    type: String,
    default: '920px'
  },
  height: {
    type: String,
    default: '600px'
  },
  zoom: {
    type: Number,
    default: 12
  },
})
const mapData = ref({
  address: '',//详细地址
  point: '',//经纬度
  latitude: '', //经度
  lng: '', //纬度
})
const emits = defineEmits(['getPoint'])
const BMap = window.BMap
let Map = null
let mk = null
onMounted(() => {
  //初始化地图 禁用地图默认点击弹框
  Map = new BMap.Map('map', { enableMapClick: false })
  Map.clearOverlays(); // 移除地图上的标注
  // 初始化 地图经纬度 层级
  let point = new BMap.Point(longitude, latitude)
  Map.centerAndZoom(point, zoom)
  Map.enableScrollWheelZoom(true)
  let marker = new BMap.Marker({
    lng: longitude,
    lat: latitude
  });
  Map.addOverlay(marker); // 将标注添加到地图中
  
  // 添加地图点击事件
  Map.addEventListener('click', function (e) {
    let clickpt = e.point; // 点击的坐标
    getAddrByPoint(clickpt)
    let marker2 = new BMap.Marker(clickpt); // 创建标注
    Map.clearOverlays(); 
    Map.addOverlay(marker2); // 将标注添加到地图中
  })
  //创建一个图像标注实例 启用拖拽 默认为false
  // mk = new BMap.Marker(point, { enableDragging: true })
  // Map.clearOverlays();
  // Map.addOverlay(mk) //将覆盖物添加到地图中
  // mk.addEventListener('dragend', function (e) {
  //   getAddrByPoint(e.point) //拖拽结束后调用逆地址解析函数，e.point为拖拽后的地理坐标
  // })


})
/**
* 根据用户输入 展示可选地区
* @param { String } str 当前用户输入 
* @param { Function } cb 渲染回调
*/
function querySearch(str, cb) {
  var options = {
    //检索完成后的回调函数
    onSearchComplete: function (res) {
      var arr = [];
      if (local.getStatus() == BMAP_STATUS_SUCCESS) {
        for (var i = 0; i < res.getCurrentNumPois(); i++) {
          arr.push(res.getPoi(i));
        }
      }
      //获取到数据时，通过回调函数cb返回到<el-autocomplete>组件中进行显示
      cb(arr)
    }
  }
  //创建LocalSearch构造函数
  var local = new BMap.LocalSearch(Map, options)
  local.search(str)
}

/**
* 选择地区
* @param { Obejct } item 
*/
function handleSelect(item) {
  // 保存当前数据
  mapData.value = {
    address: item.address,
    point: item.point.lat + ',' + item.point.lng,
    latitude: item.point.lat,
    longitude: item.point.lng
  }
  //清除地图上所有覆盖物
  Map.clearOverlays()
  //根据所选坐标重新创建Marker 记住打开可拖拽 默认是不打开的
  mk = new BMap.Marker(item.point, { enableDragging: true })
  mk.addEventListener('dragend', function (e) {
    // getAddrByPoint(e.point) //拖拽结束后调用逆地址解析函数，e.point为拖拽后的地理坐标
  })
  //将覆盖物重新添加到地图中
  Map.addOverlay(mk)
  //将地图的中心点更改为选定坐标点
  Map.panTo(item.point)
  getAddrByPoint(item.point)
}
/**
* 逆地址解析函数 根据经纬度获取详细地址
* @param {Object} point 当前经纬度
*/
function getAddrByPoint(point) {
  console.log(point);
  var geoc = new BMap.Geocoder();
  geoc.getLocation(point, function (e) {
    let info = e.addressComponents;
    let address = e.address;
    if (e.surroundingPois.length > 0) {
      address = address + '-' + e.surroundingPois[0].title;
    }
    mapData.value = {
      address: address,
      point: point.lat + ',' + point.lng,
      latitude: point.lat,
      longitude: point.lng
    }
    // mk.setPosition(point) //重新设置标注的地理坐标
    Map.panTo(point)  //将地图的中心点更改为给定的点
    emits('getPoint', (mapData))
  })
}
// 暴露需要的数据
defineExpose({ mapData })
</script>
 
<style scoped lang="scss">
.baidu_map {
  position: relative;
}

.search-div {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 9999;
  width: 300px;
  opacity: 0.8;
}

.autoAddressClass {
  // position: absolute;
  // top: 5px;
  // left: 5px;

  li {
    .title {
      line-height: 30px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .address {
      line-height: 1;
      font-size: 12px;
      color: #b4b4b4;
      margin-bottom: 5px;
    }

    .autoAddressClass_item {
      overflow: hidden;
      display: flex;
      align-items: center;

      .el-icon {
        margin-right: 20px;
      }
    }
  }
}
</style>