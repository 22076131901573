
let User = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 验证手机号是否存在
     * @param {*} userInfo 
     * @param {*} success 
     * @param {*} error 
     */
    this.existUserAccount = function (userInfo, success, error) {
		var url = ServiceBaseAddress + '/api/User/ExistUserAccount'
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			userInfo,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
    }
    this.existUserOrganization = function (account, success, error) {
		var url =
			ServiceBaseAddress + '/api/User/ExistUserOrganization/' + account
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
    }
    this.staffDetail = function (userId, success, error) {
		var url = ServiceBaseAddress + '/api/User/Info/' + userId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
    }
    /**
     * 新增用户
     * @param {any} name 用户姓名
     * @param {any} password 登录密码
     * @param {any} mobilePhone 手机号
     * @param {any} email 电子邮箱
     * @param {any} sex 性别，1：男  0：女
     * @param {any} birthday 出生日期， yyyy-MM-dd
     * @param {any} organizationId 所属组织机构
     * @param {any} departmentId 所属部门
     * @param {any} titleId 所属职称
     * @param {any} postId 所属职位
     * @param {any} success
     * @param {any} error
     */
    this.addUser = function (userInfo, success, error) {
        var url = ServiceBaseAddress + '/api/User';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, userInfo,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 修改用户信息
     * @param {any} userId
     * @param {any} name 用户姓名
     * @param {any} password 登录密码
     * @param {any} mobilePhone 手机号
     * @param {any} email 电子邮箱
     * @param {any} sex 性别，1：男  0：女
     * @param {any} birthday 出生日期， yyyy-MM-dd
     * @param {any} organizationId 所属组织机构
     * @param {any} departmentId 所属部门
     * @param {any} titleId 所属职称
     * @param {any} postId 所属职位
     * @param {any} success
     * @param {any} error
     */
    this.editUser = function (userInfo, success, error) {
        var url = ServiceBaseAddress + '/api/User';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, userInfo,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    
    /**
     * 删除用户
     * @param {any} userId
     * @param {any} success
     * @param {any} error
     */
    this.removeUser = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/User/' + userId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 设置用户状态
     * @param {*} userId 
     * @param {*} success 
     * @param {*} error 
     */
    this.enableUser = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/User/Enable/' + userId;
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    this.disableUser = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/User/Disable/' + userId;
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);

    }
    /**
     * 用户登录
     * @param {any} username
     * @param {any} password
     */
    this.Login = function (username, password) {
        TokenClient.SetUser(username, password);
        if (TokenClient.Token()) {

            analysisToken(TokenClient.Token().AccessToken);
            return true;
        }
        else {
            return false;
        }
    }
    this.Manager = function (organizationId, keyWord,success, error) {
        var url = ServiceBaseAddress + '/api/User/Key/Manager/';
        if (organizationId) {
            url += organizationId;
        }
        else {
            url += 0;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.User = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/User/Key/Users/';
        if (organizationId) {
            url += organizationId + '/0';
        }
        else {
            url += '0/0';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    
    this.DisableUser = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/User/Disable/' + userId;
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);

    }

    this.AllUser =function (success, error) {
        var url = ServiceBaseAddress + '/api/User';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    
    this.UserInfo = function () { }
    /**
     * 修改当前登录用户的密码
     * @param {any} oldPassword 旧密码
     * @param {any} newPassword 新密码
     * @param {any} success
     * @param {any} error
     */
    this.ChangePassword = function (oldPassword, newPassword, success, error) {
        var url = ServiceBaseAddress + '/api/User/ChangePassword';
        var param = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 设定指定用户的密码
     * @param {any} userId
     * @param {any} password
     * @param {any} success
     * @param {any} error
     */
    this.ResetPassword = function (userId, password, success, error) {
        var url = ServiceBaseAddress + '/api/User/SetUserPassword';
        var param = {
            userId: userId,
            password: password
        };
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取所有普通用户
     * @param success
     * @param error
     * @constructor
     */
    this.UserAll = function ( pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/UserRelation/UserAll';
        if(pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }else {
            url += `/%20`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.AllUsers = function ( keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/UserRelation/UserAllAdmin';
        if (keyWord) {
            url += `/${keyWord}`;
        }else {
            url += `/%20`;
        }

        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.userDetail = function ( userId, success, error) {
        var url = ServiceBaseAddress + '/api/User/Info/'+ userId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    
    /*获取Session中的用户信息 */
    this.GetSessionUserInfo = function () {
        var result = sessionStorage.getItem("userInfo");
        if (result == null) {
            result == null;
            // location.href="/Login";
        } else {
            result = JSON.parse(result);
        }
        return result;
    }
    this.ImportUserData = function (path, success, error) {
        var url = ServiceBaseAddress + `/api/User/ImportUserData?filePath=${path}`;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    this.ImportUserDataAgent = function (path, success, error) {
        var url = ServiceBaseAddress + `/api/User/ImportUserDataAgent?filePath=${path}`;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    this.ImportUserDataReplace = function (orgId,path, success, error) {
        var url = ServiceBaseAddress + `/api/User/ImportUserDataReplace?orgId=${orgId}&filePath=${path}`;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
}

export { User };

//解析JWT
function analysisToken(token) {
	let strings = token.split('.') //截取token，获取载体
	var userinfo = JSON.parse(
		decodeURIComponent(
			escape(
				window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))//解析，需要吧‘_’,'-'进行转换否则会无法解析
			)
		)
	);
	if (userinfo.sub) {
        var user ={
            id: parseInt(userinfo.sub ?? 0), //用户ID
            name: userinfo.UserName, //用户姓名
            phone: userinfo.phone, //电话号
            // telephone: userinfo.telephone,
            // address: userinfo.address,
            abstract: userinfo.abstract,
            sex: userinfo.Sex, //性别
            photo: userinfo.Photo, //头像
            organizationId: parseInt(userinfo.OrganizationId ?? 0), //所属单位ID
            organizationName: userinfo.OrganizationName, //所属单位名称
            departmentId: parseInt(userinfo.DepartmentId ?? 0), //所属科室ID
            department: userinfo.Department, //所属科室名称
            title: userinfo.Title, //职称
            titleId: parseInt(userinfo.TitleId ?? 0), //职称ID
            post: userinfo.Post, //职务
            postId: parseInt(userinfo.PostId ?? 0), //职务ID
            roleId: parseInt(userinfo.RoleId ?? 0),
            agentId:parseInt(userinfo.AgentId ?? 0),
            agentName:userinfo.AgentName,
            productId:parseInt(userinfo.ProductId ?? 0),
        };
        sessionStorage.setItem("userInfo",JSON.stringify(user));  //将token存储起来
	}

}
