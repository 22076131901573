import { Discovery } from './Discovery.js';
import { ApiClient } from './ApiClient.js';
/**
 * 权限认证中心客户端
 * @param {any} serverAddr 权限认证中心的服务器地址
 * @param {any} clientId ClientId
 * @param {any} secret Secret
 * @param {any} username 用户名
 * @param {any} password 登录密码
 * @param {any} scope ClientScope
 */
let TokenClient = function (serverAddr, clientId, secret, username, password, scope) {
    if (!clientId || !secret) throw new Error("参数设置错误");
    var _this = this;
    /**
     * 权限认证中心的服务器地址
     * */
    let ServerAddress = serverAddr;
    /**
    * 客户端配置信息
    * */
    let Client = new ClientInfo(clientId, secret, username, password, scope);
    /**
    * 最后一次请求Token的时间
    * */
    let RequestTime = 0;
    /**
    * 服务器发现服务
    * */
    let DiscoveryDocument = new Discovery(ServerAddress);
    /**
    * Token信息
    * */
    let TokenData = null;
    /**
     * 是否已登录
     */
    let IsLogin=false;

    /**
    * 请求Token
    * @param {any} param 请求时发送的参数对象
    * */
    function RequestToken(param) {
        var tokenEndpoint = DiscoveryDocument.Document()?.TokenEndpoint;
        ApiClient.Post(tokenEndpoint, false, ApiClient.UrlEncodedHeader, param, function (data) {
            RequestTime = new Date();
            TokenData = new TokenInfo(data);
            IsLogin = true;
            sessionStorage.setItem(Client.ClientId + 'Token', data);
            sessionStorage.setItem(Client.ClientId + 'TokenRequestTime', RequestTime);
        }, undefined);
    }
    _this.IsLogin = function(){
        return IsLogin;
    }
    _this.SetClient = function (clientId, secret, scope) {
        Client.ClientId = clientId;
        Client.Secret = secret;
        Client.Scope = scope;
        TokenData = null;
        IsLogin = false;
        _this.Token();
    }
    _this.SetUser = function (username, password) {
        Client.Username = username;
        Client.Password = password;
        TokenData = null;
        sessionStorage.removeItem(Client.ClientId + 'Token');
        sessionStorage.removeItem(Client.ClientId + 'TokenRequestTime');
        _this.Token();
    }

    _this.IsUser = function () {
        if (_this.RefreshToken()) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
    * 从缓存中获取Token或从服务器请求Token
    * */
    _this.Token = function () {
        var sessionTokenStr = sessionStorage.getItem(Client.ClientId + 'Token');
        var sessionTokenRequestTimeStr = sessionStorage.getItem(Client.ClientId + 'TokenRequestTime');
        var sessionTokenRequestTime = 0;

        if (sessionTokenRequestTimeStr && sessionTokenStr) {
            sessionTokenRequestTime = new Date(sessionTokenRequestTimeStr);

            if (sessionTokenRequestTime > RequestTime) {
                TokenData = new TokenInfo(sessionTokenStr);
                RequestTime = sessionTokenRequestTime;
                IsLogin = true;
            }
        }
        if (TokenData && RequestTime) {
            var currertTime = new Date();
            var duration = (currertTime - RequestTime) / 1000;
            if (duration >= TokenData.ExpiresIn) {
                if (TokenData.RefreshToken) {
                    RequestToken(new TokenRefreshParam(Client, TokenData.RefreshToken));
                }
                else {
                    RequestToken(new TokenRequestParam(Client));
                }
            }
        }
        else {
            RequestToken(new TokenRequestParam(Client));
        }
        return TokenData;
    }
    /**
    * 获取AccessToken
    * */
    _this.AccessToken = function () {
        var token = _this.Token();
        return token.AccessToken;
    }
    /**
    * 获取RefreshToken
    * */
    _this.RefreshToken = function () {
        if(IsLogin) {
            var token = _this.Token();
            return token.RefreshToken;
        }
        return false;
    }
    /**
    * 获取AccessToken的有效期
    * */
    _this.ExpiresIn = function () {
        var token = _this.Token();
        return token.ExpiresIn;
    }
    /**
     * 获取AccessToken的TokenScope
     * */
    _this.TokenScope = function () {
        var token = _this.Token();
        return token.TokenScope;
    }
    /**
    * 获取AccessToken的类型
    * */
    _this.TokenType = function () {
        var token = _this.Token();
        return token.TokenType;
    }
    /**
    * 生成附带AccessToken的Header
    * */
    _this.TokenHeader = function () {
        var token = _this.Token();
        if (token && token.AccessToken) {
            return { 'Authorization': token.TokenType + ' ' + token.AccessToken };
        }
        else {
            return {};
        }
    }
    _this.Token();
}
/**
 * 发送Post请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 */
TokenClient.prototype.Post = function (url, sync, header, param, success, error) {
    var mergeHeader = Object.assign({}, header, this.TokenHeader());
    ApiClient.Post(url, sync, mergeHeader, param, success, error);
}
/**
 * 发送Put请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 */
TokenClient.prototype.Put = function (url, sync, header, param, success, error) {
    var mergeHeader = Object.assign({}, header, this.TokenHeader());
    ApiClient.Put(url, sync, mergeHeader, param, success, error);
}
/**
 * 发送Get请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 */
TokenClient.prototype.Get = function (url, sync, header, param, success, error) {
    var mergeHeader = Object.assign({}, header, this.TokenHeader());
    ApiClient.Get(url, sync, mergeHeader, param, success, error);
}
/**
 * 发送Delete请求
 * @param {any} url 请求的地址
 * @param {any} sync 是否启用异步，为false时函数将等待请求完成后返回
 * @param {any} header 设置发送请求的Header，{ key1:val1 }
 * @param {any} param 设置请求的参数，{ name: val }
 * @param {any} success 请求成功回调函数，传入请求地址返回的字符串内容
 * @param {any} error 请求失败回调函数，传入异常信息
 */
TokenClient.prototype.Delete = function (url, sync, header, param, success, error) {
    var mergeHeader = Object.assign({}, header, this.TokenHeader());
    ApiClient.Delete(url, sync, mergeHeader, param, success, error);
}
/**
 * 权限认证中心客户端
 * @param {any} clientId
 * @param {any} secret
 * @param {any} username
 * @param {any} password
 * @param {any} scope
 */
let ClientInfo = function (clientId, secret, username, password, scope) {
    this.ClientId = clientId;
    this.Secret = secret;
    this.Username = username;
    this.Password = password;
    this.Scope = scope;
}
/**
 * 申请Token请求参数
 * @param {any} client 客户端配置信息
 */
let TokenRequestParam = function (client) {
    this.client_id = client?.ClientId;
    this.client_secret = client?.Secret;
    this.scope = client?.Scope;
    if (client?.Username && client?.Password) {
        this.grant_type = 'password';
        this.username = client?.Username;
        this.password = client?.Password;
    }
    else {
        this.grant_type = 'client_credentials';
    }
}
/**
 * 刷新Token请求参数
 * @param {any} client 客户端配置信息
 * @param {any} refreshToken RefreshToken
 */
let TokenRefreshParam = function (client, refreshToken) {
    this.client_id = client?.ClientId;
    this.client_secret = client?.Secret;
    this.grant_type = 'refresh_token';
    this.refresh_token = refreshToken;
}
/**
 * 获取Token结果
 * @param {any} json 获取Token接口返回的值
 */
let TokenInfo = function (json) {
    var result = undefined;
    switch (typeof json) {
        case 'string':
            result = JSON.parse(json);
            break;
        case 'object':
            result = json;
            break;
    }

    this.AccessToken = result?.access_token;
    this.RefreshToken = result?.refresh_token;
    this.ExpiresIn = result?.expires_in;
    this.TokenScope = result?.scope;
    this.TokenType = result?.token_type;
}

export { TokenClient }