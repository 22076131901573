import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue') /*使用箭头函数的使用意义：按需加载（懒加载）*/
  },
  {

    path: '/',
    name: 'System',
    component: () => import('../views/index.vue'),
    meta: {
      title: '系统管理'
    },
    children: [
      {
        path: '/system/role', /*角色权限*/
        name: 'Role',
        component: () => import('../views/System/Role.vue'),
        meta: {
          title: '角色管理'
        }
      }, {
        path: '/system/manager', /*管理员管理*/
        name: 'manager',
        component: () => import('../views/System/Manager.vue'),
        meta: {
          title: '管理员管理'
        }
      },
      {
        path: '/system/BankCard', /*机构银行卡*/
        name: 'BankCard',
        component: () => import('../views/System/BankCard.vue'),
        meta: {
          title: '银行卡管理'
        }
      },
      {
        path: '/system/OpLog', /*操作日志*/
        name: 'OpLog',
        component: () => import('../views/System/OpLog.vue'),
        meta: {
          title: '操作日志管理'
        }
      },
      {
        path: '/system/MsgList', /*操作日志*/
        name: 'MsgList',
        component: () => import('../views/System/MsgList.vue'),
        meta: {
          title: '消息通知'
        }
      },
      {
        path: '/index', /*首页*/
        name: 'index',
        component: () => import('../views/Home/index.vue')
      },
      {
        path: '/Editor', /*首页*/
        name: 'Editor',
        component: () => import('../views/Home/Editor.vue')
      },
      //商城管理
      {
        path: '/ShopMall/addCommodity',/*新增商品*/
        name: 'addCommodityShopMall',
        component: () => import('../views/ShopMall/addCommodity.vue'),
      },
      {
        path: '/ShopMall/auditCommodity',/*待审核商品*/
        name: 'auditCommodity',
        component: () => import('../views/ShopMall/auditCommodity.vue'),
      },
      {
        path: '/ShopMall/seedBackCommodity',/*驳回商品*/
        name: 'seedBackCommodity',
        component: () => import('../views/ShopMall/seedBackCommodity.vue'),
      },
    ]
  },
  {
    path: '/',
    name: 'User',
    component: () => import('../views/index.vue'),
    meta: {
      title: '用户管理'
    },
    children: [
      {
        path: '/User/subordinateAgents', /*代理商管理*/
        name: 'subordinateAgents',
        component: () => import('../views/User/subordinateAgents/subordinateAgents.vue'),
        meta: {
          title: '下级代理商管理'
        }
      },
      {
        path: '/User/subordinateAgents/User', /*代理商-用户*/
        name: 'subordinateAgentsUser',
        component: () => import('../views/User/subordinateAgents/User.vue'),
        meta: {
          title: '代理商 - 用户列表'
        }
      },
      {
        path: '/User/subordinateAgents/Merchant', /*代理商-商家*/
        name: 'subordinateAgentsMerchant',
        component: () => import('../views/User/subordinateAgents/Merchant.vue'),
        meta: {
          title: '代理商 - 商家列表'
        }
      },
      {
        path: '/User/subordinateAgents/Org', /*代理商-机构*/
        name: 'subordinateAgentsOrg',
        component: () => import('../views/User/subordinateAgents/Org.vue'),
        meta: {
          title: '代理商 - 机构列表'
        }
      },
      //用户管理
      {
        path: '/user/Org/OrgManage', /*机构管理*/
        name: 'OrgManage',
        component: () => import('../views/User/Org/OrgManage.vue'),
        meta: {
          title: '机构管理'
        }
      }, {
        path: '/user/Org/OrgUserManage', /*机构管理*/
        name: 'OrgUserManage',
        component: () => import('../views/User/Org/OrgUserManage.vue'),
        meta: {
          title: '机构 - 员工管理'
        }
      },{
        path: '/user/Org/Apply', /*开通商城*/
        name: 'Apply',
        component: () => import('../views/User/Org/Apply.vue'),
        meta: {
          title: '机构 - 开通商城'
        }
      },{
        path: '/user/Org/changeApply', /*开通商城*/
        name: 'changeApply',
        component: () => import('../views/User/Org/changeApply.vue'),
        meta: {
          title: '机构 - 开通商城'
        }
      },
      {
        path: '/user/MerchantManage', /*商家管理*/
        name: 'MerchantManage',
        component: () => import('../views/User/MerchantManage.vue'),
        meta: {
          title: '商家管理'
        }
      },
      {
        path: '/user/UserManage', /*用户管理*/
        name: 'UserManage',
        component: () => import('../views/User/UserManage.vue'),
        meta: {
          title: '会员管理'
        }
      }, 
      {
        path: '/user/UserManageSub', /*用户管理*/
        name: 'UserManageSub',
        component: () => import('../views/User/UserManageSub.vue'),
        meta: {
          title: '会员管理-直接下级'
        }
      }, 
      {
        path: '/user/UserManageAllSub', /*用户管理*/
        name: 'UserManageAllSub',
        component: () => import('../views/User/UserManageAllSub.vue'),
        meta: {
          title: '会员管理-所有下级'
        }
      }, 
      {
        path: '/user/UserVersionRecord', /*用户升级记录*/
        name: 'UserVersionRecord',
        component: () => import('../views/User/UserVersionRecord.vue'),
        meta: {
          title: '会员升级记录'
        }
      }, 
      {
        path: '/user/UserAssetsDetail', /*用户账单*/
        name: 'UserAssetsDetail',
        component: () => import('../views/User/UserAssetsDetail.vue'),
        meta: {
          title: '会员账单'
        }
      }, {
        path: '/user/OrgAudit', /*机构审核*/
        name: 'OrgAudit',
        component: () => import('../views/User/OrgAudit.vue'),
        meta: {
          title: '机构审核'
        },
      }, {
        path: '/user/MerchantAudit', /*商家审核*/
        name: 'MerchantAudit',
        component: () => import('../views/User/MerchantAudit.vue'),
        meta: {
          title: '商家审核'
        }
      }, 
      //个人中心
      {
        path: '/user/personalData', /*个人资料*/
        name: 'personalData',
        component: () => import('../views/User/Profile.vue'),
      }, {
        path: '/user/resetPwd', /*修改密码*/
        name: 'resetPwd',
        component: () => import('../views/User/ResetPwd.vue'),
      },
    ]
  },
  {
    path: '/',
    name: 'shopMall',
    component: () => import('../views/index.vue'),
    meta: {
      title: '商城功能'
    },
    children: [
      {
        path: '/ShopMall/setTing', /*商城设置*/
        name: 'setTing',
        component: () => import('../views/ShopMall/setTing.vue'),
        meta: {
          title: '商城设置'
        }
      }, {
        path: '/ShopMall/commodity', /*商品列表*/
        name: 'commodity',
        component: () => import('../views/ShopMall/commodity.vue'),
        meta: {
          title: '商品列表'
        }
      },  
    ]
  },
  {
    path: '/',
    name: '',
    component: () => import('../views/index.vue'),
    meta: {
      title: '财务管理'
    }, children: [
      //财务管理
      {
        path: '/Finance/VipOrder',/*会员缴费记录*/
        name: 'VipOrder',
        component: () => import('../views/Finance/VipOrder.vue'),
        meta: {
          title: '会员升级记录'
        },
      }, {
        path: '/Finance/banKpayOrder',/*会员缴费记录*/
        name: 'banKpayOrder',
        component: () => import('../views/Finance/banKpayOrder.vue'),
        meta: {
          title: '对公账户审批'
        },
      }, {
        path: '/Finance/orgOrderpay',/*会员缴费记录*/
        name: 'orgOrderpay',
        component: () => import('../views/Finance/orgOrderpay.vue'),
        meta: {
          title: '机构升级申请'
        },
      }, {
        path: '/Finance/orgOrder',/*会员缴费记录*/
        name: 'orgOrder',
        component: () => import('../views/Finance/orgOrder.vue'),
        meta: {
          title: '机构升级记录'
        },
      }, {
        path: '/Finance/Balance',/*余额管理*/
        name: 'Balance',
        component: () => import('../views/Finance/Balance.vue'),
        meta: {
          title: '账户管理'
        },
      }, {
        path: '/Finance/Payment',/*提现打款管理*/
        name: 'Payment',
        component: () => import('../views/Finance/Payment.vue'),
        meta: {
          title: '提现审批'
        },
      }, 
    ]
  },
  {
    path: '/',
    name: 'QRCode',
    component: () => import('../views/index.vue'),
    meta: {
      title: '推广码'
    },
    children: [
      {
        path: '/QRCode/myQRCode', /*推广二维码*/
        name: 'myQRCode',
        component: () => import('../views/myQRCode/myQRCode.vue'),
        meta: {
          title: '推广二维码'
        }
      }
    ]
  },
  {
    path: '/',
    name: 'Msg',
    component: () => import('../views/index.vue'),
    meta: {
      title: '消息'
    },
    children: [
      {
        path: "/Message/PlatformMes" /*消息*/,
        name: "PlatformMes",
        component: () => import("../views/Message/PlatformMes.vue"),
        meta: {
          title: '消息管理'
        }
      },
    ]
  },
  {
    path: '/',
    name: 'STATS',
    component: () => import('../views/index.vue'),
    meta: {
      title: '数据统计'
    },
    children: [
       {
        path: '/STATS/membershipStatistics', /*公益分红记录*/
        name: 'membershipStatistics',
        component: () => import('../views/STATS/membershipStatistics.vue'),
        meta: {
          title: '会员账单统计'
        }
      }, {
        path: '/STATS/organizationStatistics', /*公益分红记录*/
        name: 'organizationStatistics',
        component: () => import('../views/STATS/organizationStatistics.vue'),
        meta: {
          title: '机构账单统计'
        }
      }, {
        path: '/STATS/agentStatistics', /*公益分红记录*/
        name: 'agentStatistics',
        component: () => import('../views/STATS/agentStatistics.vue'),
        meta: {
          title: '代理账单统计'
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
