<template>
  <div class="iq-top-navbar">
    <nav class="navbar navbar-expand-lg">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="padding: 0 15px"
      >
        <el-breadcrumb-item
          v-for="(item, index) in breadList"
          :key="index"
          :to="{ path: item.path }"
          >{{ item.meta.title }}</el-breadcrumb-item
        >
      </el-breadcrumb>
      <head-center></head-center>
    </nav>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"; //导入路由
const route = useRoute();
const breadList = ref([]);
onMounted(() => {
  //生命周期-挂载完成
  getBreadcrumb(route.matched);
});
function getBreadcrumb(matched) {
  if (isHome(matched[0])) {
    matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
  }
  breadList.value = matched;
}
function isHome(route) {
  return route.name === "首页";
}
</script>
<style scoped>
.iq-top-navbar {
  padding: 0;
  min-height: 73px;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  width: calc(100% - 320px);
  display: inline-block;
  z-index: 1;
  background: #ffffff;
  margin: 20px 30px;
  box-shadow: 0px 0px 15px rgba(48, 73, 101, 0.1);
  transition: all 0.45s ease 0s;
  border-radius: 25px;
  -webkit-border-radius: 25px;
}

.el-page-header {
  height: 73px;
  padding-left: 20px;
  display: flex;
}

.navbar-expand-lg {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar {
  position: relative;
  height: 73px;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header {
  flex: 1;
  text-align: right;
  margin-right: 15px;
}

.messages {
  margin-top: 13px;
  margin-right: 20px;
}

.messages .el-icon-bell {
  font-size: 26px;
}
</style>

